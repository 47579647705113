export class SiteInfoAddress {
    name: string = '';
    address: string = '';

    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.name = data.name
        this.address = data.address
    }
}

export class SiteInfoGeneral {
    addresses: SiteInfoAddress[] = [];
    contactEmail: string = '';
    description: string = '';
    name: string = '';
    shortDescription: string = '';
    currency: string = '';
    currencySymbol: string = '$';
    paymentProviders: PaymentProvider[] = [];

    constructor(data?: any) {
        if (!data) {
            return;
        }

        if (data?.addresses) {
            this.addresses = data.addresses.map((d: any) => new SiteInfoAddress(d))
        }

        this.contactEmail = data.contactEmail
        this.description = data.description
        this.name = data.name
        this.shortDescription = data.shortDescription
        this.currency = data.currency
        this.currencySymbol = data.currencySymbol
        this.paymentProviders = data.paymentProviders
    }
}

export class SiteInfoAuth {
    allowPostpaidSignup: boolean = false;
    autoApproveNewCustomer: boolean = false;

    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.allowPostpaidSignup = data.allowPostpaidSignup
        this.autoApproveNewCustomer = data.autoApproveNewCustomer
    }
}

export class SiteInfoBranding {
    favicon: string = '';
    logo: string = '';

    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.favicon = data.favicon
        this.logo = data.logo
    }
}

export class SiteInfo {
    auth?: SiteInfoAuth;
    branding?: SiteInfoBranding;
    general?: SiteInfoGeneral;

    constructor(data?: any) {
        if (!data) {
            return;
        }

        this.auth = new SiteInfoAuth(data?.auth)
        this.branding = new SiteInfoBranding(data?.branding)
        this.general = new SiteInfoGeneral(data?.general)
    }
}

export enum PaymentProvider {
    PayPal = 'paypal',
    Eway = 'eway',
}